import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { getSrc } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';

import Layout from '../../components/layout';
import TipDate from '../../components/tip-date';
import SEO from '../../components/seo';
import TipFooter from '../../components/tip-footer';
import { tipWrapper } from './tip.module.scss';

const generateSchema = (
    title,
    imageUrl,
    location,
    datePublished,
    dateModified,
    description
) => {
    return `{
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": "${title}",
        "image": "https://www.webperf.tips${imageUrl}",
        "author": {
            "@type": "Person",
            "familyName": "Liccini",
            "gender": "Male",
            "givenName": "Joe",
            "jobTitle": "Software Engineer",
            "worksFor": {
                "@type": "Organization",
                "name": "Stripe"
            },
            "image": "https://www.webperf.tips/static_Persona_Image.jpg",
            "url": "https://www.webperf.tips/about"
        },
        "url": "https://www.webperf.tips${location.pathname}",
        "datePublished": "${new Date(datePublished).toISOString()}",
        "dateModified": "${dateModified}",
        "description": "${description}",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.webperf.tips"
        }
    }`;
};

const TipPost = ({ data }) => {
    const image = getSrc(data.mdx.frontmatter.image);
    const title = data.mdx.frontmatter.title;
    const description = data.mdx.frontmatter.description;
    const publishDate = data.mdx.frontmatter.date;
    const modifiedDate = data.mdx.frontmatter.last_modified_date;
    const location = useLocation();
    const schema = generateSchema(
        title,
        image,
        location,
        data.mdx.frontmatter.isoPublishDate,
        data.mdx.frontmatter.isoModifiedDate,
        description
    );

    return (
        <>
            <SEO
                title={title}
                description={description}
                image={image}
                article={true}
                schema={schema}
            />
            <Layout pageTitle={title}>
                <div className={tipWrapper}>
                    <TipDate
                        postDate={publishDate}
                        modifiedDate={modifiedDate}
                    />
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    <TipFooter
                        tipTopics={data.mdx.frontmatter.topics || []}
                    ></TipFooter>
                </div>
            </Layout>
        </>
    );
};

export const query = graphql`
    query ($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                date(formatString: "MMMM D, YYYY")
                isoPublishDate: date
                last_modified_date(formatString: "MMMM D, YYYY")
                isoModifiedDate: last_modified_date
                description
                topics
                image {
                    childImageSharp {
                        gatsbyImageData(aspectRatio: 2)
                    }
                }
            }
            body
        }
    }
`;

export default TipPost;
