import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { rssButtonLink } from './rss-button.module.scss';

const RssButton = () => {
    return (
        <a href="/rss.xml" className={rssButtonLink}>
            <StaticImage
                src="../images/rss-icon.png"
                height={16}
                width={16}
                alt="Subscribe to the Web Performance Tips RSS Feed" />
        </a>
    );
}

export default RssButton;
