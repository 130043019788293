import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { useMemo } from 'react';
import { commaSeparate } from '../utils/comma-separate-grammar';

const TopicLink = ({ topic }) => {
    return (
        <a href={`/topic/${topic.topicId}`}>{topic.displayName}</a>
    );
}

const RelatedTopicsComponent = ({ tipTopics }) => {
    const data = useStaticQuery(graphql`
        query {
            allTopicsJson {
                nodes {
                    displayName
                    topicId
                }
            }
        }`
    );

    const relatedTopics = useMemo(() => {
        const allTopics = data.allTopicsJson.nodes || [];
        return allTopics.filter(topic => tipTopics.includes(topic.topicId));
    }, [tipTopics, data]);

    const commaSeparatedTopics = useMemo(() => {
        return commaSeparate(relatedTopics.map(topic => <TopicLink key={topic.topicId} topic={topic} />));
    }, [relatedTopics])

    return relatedTopics.length ? (
        <i>
            <span>Discover more similar tips matching </span>
            {commaSeparatedTopics}.
        </i>
    ) : null;
};

export default RelatedTopicsComponent;
