export function commaSeparate(items) {
    if (items.length === 1) {
        return items;
    }

    if (items.length === 2) {
        return [ items[0], " and ", items[1] ];
    }

    const returnValue = [];

    for (let i = 0; i < items.length; ++i) {
        returnValue.push(items[i]);

        if (i < items.length - 2) {
            returnValue.push(", ");
        } else if (i === items.length - 2) {
            returnValue.push(", and ");
        }
    }

    return returnValue;
}
