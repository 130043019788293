import * as React from 'react';

import { arrowContainer,backToTopButton, backToTopButtonContainer } from './back-to-top.module.scss';

const BackToTopButton = () => {
    const scrollToTop = React.useCallback(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div className={backToTopButtonContainer}>
            <button className={backToTopButton} onClick={scrollToTop}>
                Back to Top <span className={arrowContainer}>↑</span>
            </button>
        </div>
    )
};

export default BackToTopButton;
