import * as React from 'react';
import { Link } from 'gatsby';
import { contactLink } from './contact.module.scss';

const Contact = ({ showName }) => (
    <section>
        <h2>Get in Touch!</h2>
        <p>
            {showName ? "Hi, I'm Joe Liccini, and " : ''}I am always looking for
            new scenarios to optimize!
        </p>
        <p>
            If your web product has performance issues{' '}
            <Link to="/tip/collect-a-trace" className={contactLink}>
                Collect a Trace
            </Link>{' '}
            and send it to me at <strong>joe</strong> <em>at</em>{' '}
            <strong>webperf.tips</strong> and you could be featured in an
            article!
        </p>
        <p>
            Engineers at Stripe can contact me directly using available
            internal channels.
        </p>
    </section>
);

export default Contact;
