import * as React from 'react';
import Contact from './contact';
import Persona from './persona';
import BackToTopButton from './back-to-top-button';

import { closingTextContainer, closingTextItem, tipFooterContainer, tipFooterPersona, tipFooterContact } from './tip-footer.module.scss';
import RelatedTopicsComponent from './related-topics';
import RssButton from './rss-button';

const TipFooter = ({ tipTopics }) => {
    return (
        <>
            <p>
                That's all for this tip! Thanks for reading! <RelatedTopicsComponent tipTopics={tipTopics} />
            </p>
            <div className={closingTextContainer}>
                <div className={closingTextItem}>
                    <RssButton showText={true} />
                </div>
                <BackToTopButton className={closingTextItem} />
            </div>
            <div className={tipFooterContainer}>
                <div className={tipFooterPersona}>
                    <Persona></Persona>
                </div>
                <div className={tipFooterContact}>
                    <Contact showName={true}></Contact>
                </div>
            </div>
        </>
    )
}

export default TipFooter;
