import * as React from 'react';

import RssButton from './rss-button';
import { tipHeaderDecorationContainer, tipPostDateResponsiveComma, tipDate, tipDateItem, rssDecoration, rssDecorationContainer } from './tip-date.module.scss';

const TipDate = ({ postDate, modifiedDate }) => {
    const shouldRenderModifiedDate = postDate !== modifiedDate;

    return (
        <>
            <div className={tipHeaderDecorationContainer}>
                <div className={tipDate}>
                    <span className={tipDateItem}>
                        <em>Posted On:</em> <span className={shouldRenderModifiedDate ? tipPostDateResponsiveComma : ''}>{postDate}</span>
                    </span>
                    {
                        shouldRenderModifiedDate ?
                            <span className={tipDateItem}><em>Last Updated On:</em> {modifiedDate}</span> :
                            null
                    }
                </div>
                <div className={rssDecorationContainer}>
                    <span className={rssDecoration}>
                        <RssButton className={rssDecoration} />
                    </span>
                </div>
            </div>
        </>
    )
}

export default TipDate;
