import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { personaImage } from './persona.module.scss';

const Persona = () => {
    return (
        <StaticImage
            src="../images/Persona_Image.jpg"
            alt="A photo of Joe Liccini"
            className={personaImage} />
    );
}

export default Persona;
